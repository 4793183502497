<template>
  <div class="page" id="page">
    <div class="mine">
      <div class="top" style="margin-top: 80px">
        <div class="title">
          <div class="lent"></div>
          <div class="title-text">物业缴费</div>
          <div class="lent"></div>
        </div>
        <p class="title-bottom-font">智慧缴费管理解决方案</p>
      </div>

      <div class="content">
        <div>
          <el-image
            style="width: 360px; height: 207px"
            :src="require('../assets/platformp/pay.png')"
          ></el-image>
          <p>居民小区</p>
        </div>
        <div class="center">
          <el-image
            style="width: 360px; height: 207px"
            :src="require('../assets/platformp/pay1.png')"
          ></el-image>
          <p>住宅公寓</p>
        </div>
        <div>
          <el-image
            style="width: 360px; height: 207px"
            :src="require('../assets/platformp/pay2.png')"
          ></el-image>
          <p>员工宿舍</p>
        </div>
      </div>

      <div class="text">
        未来+未来+物业缴费管理系统是一套官方适用于居民小区、住宅公寓、员工宿舍等智慧物业支付管理系统，实现计费，收费催缴以及财务对账等完整功能，只需安装APP，即可快速奖励物业费用在线收缴中心，轻松提高物业费用收缴率，彻底杜绝收费漏洞。
      </div>

      <div class="list">
        <div class="box-val">
          <el-image
            style="width: 60px; height: 60px"
            :src="require('../assets/platformp/p6.png')"
          ></el-image>
          <p class="name">费用收缴</p>
        </div>
        <div class="box-val">
          <el-image
            style="width: 60px; height: 60px"
            :src="require('../assets/platformp/p1.png')"
          ></el-image>
          <p class="name">账单通知</p>
        </div>
        <div class="box-val">
          <el-image
            style="width: 60px; height: 60px"
            :src="require('../assets/platformp/p2.png')"
          ></el-image>
          <p class="name">支付管理</p>
        </div>
        <div class="box-val">
          <el-image
            style="width: 60px; height: 60px"
            :src="require('../assets/platformp/p3.png')"
          ></el-image>
          <p class="name">财务对账</p>
        </div>
        <div class="box-val">
          <el-image
            style="width: 60px; height: 60px"
            :src="require('../assets/platformp/p4.png')"
          ></el-image>
          <p class="name">缴费控制</p>
        </div>
        <div class="box-val">
          <el-image
            style="width: 60px; height: 60px"
            :src="require('../assets/platformp/p5.png')"
          ></el-image>
          <p class="name">账单明细</p>
        </div>
      </div>

      <div class="top" style="margin-top: 80px; width: 510px">
        <div class="title">
          <div class="lent"></div>
          <div class="title-text">未来+物业缴费管理系统</div>
          <div class="lent"></div>
        </div>
      </div>

      <p class="pfont">
        物业前台首款中心，方便住户线下缴费，支持费用预收、临时收费。可精确查看每户的收费情况和欠费情况，包括应收、已缴、未缴账单已缴、
      </p>

      <div class="p-bottom">
        <div class="bottom-l">
          <el-image
            style="width: 550px; height: 350px"
            :src="require('../assets/platformp/bj.png')"
          ></el-image>

          <p class="title-bottom">缴费控制台</p>

          <p class="nr-bottom">
            物业前台收款中心，方便住户线下缴费，支付费用预收、临时收费，可精确查看每户的欠费情况和收费情况、包括应收、已缴、未缴账单。
          </p>
        </div>

        <div class="bottom-r">
          <el-image
            style="width: 550px; height: 350px"
            :src="require('../assets/platformp/bj1.png')"
          ></el-image>

          <p class="title-bottom">缴费控制台</p>

          <p class="nr-bottom">
            物业前台收款中心，方便住户线下缴费，支付费用预收、临时收费，可精确查看每户的欠费情况和收费情况、包括应收、已缴、未缴账单。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
<style scoped lang="less">
.page {
  width: 100%;
  text-align: center;
  .mine {
    width: 60%;
    margin: auto;
  }

  .top {
    width: 300px;
    margin: auto;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lent {
        width: 48px;
        height: 2px;
        background-color: #333333;
        // border: 1px solid #333333;
      }
      .title-text {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
      }
    }
    .title-bottom-font {
      font-weight: 400;
      color: #999999;
      font-size: 16px;
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    p {
      height: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
    }
    .center {
      margin: 0 20px;
    }
  }
  .text {
    text-align: left;
    height: 74px;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 27px;
    box-sizing: border-box;
    padding: 0 0px 0 10px;
    margin: 50px 0;
  }
  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-val {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 181px;
      height: 210px;
      background: rgba(223, 247, 231);
      .name {
        margin-top: 30px;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .pfont {
    text-align: left;
    height: 47px;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 27px;
    margin-bottom: 20px;
  }
  .p-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
    .bottom-l,
    .bottom-r {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .title-bottom {
        height: 23px;
        font-size: 22px;
        font-weight: bold;
        color: #333333;
      }
      .nr-bottom {
        text-align: left;
        width: 531px;
        height: 74px;
        font-size: 20px;
        font-weight: 400;
        color: #999999;
        line-height: 27px;
      }
    }
  }
}
</style>
